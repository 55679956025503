import { isScreenLg } from '@@/utils/CommonUtils';

/**
 * Common colors used in charts by name.
 */
export const chartColors = {
  bar: '#2a7ebb',
  barBackground: (theme) => (theme === 'dark' ? '#181818' : '#f6f6f6'), // var(--card-alternate-row)
  borderColor: (theme) => (theme === 'dark' ? '#ffffff73' : '#dee2e6'), // var(--border-color)
  borderColorDark: '#565656',
  now: '#e38064',
  saturatedBlue: '#297dbb', // var(--saturated-blue)
  saturatedRed: '#df2b1d', // var(--saturated-red)
  skeletonColor: (theme) => (theme === 'dark' ? '#43474b' : '#dee2e6'), // var(--skeleton-copy)
  textDark: (theme) => (theme === 'dark' ? '#ffffffd9' : '#495057'), // var(--text-dark)
  textDarkest: (theme) => (theme === 'dark' ? '#fff' : '#000'), // var(--text-darkest)
  textRegular: (theme) => (theme === 'dark' ? '#ffffffbf' : '#6c757d'), // var(--text-regular)
  unknown: '#777',
};

export const canBeResponsive = () => {
  if (typeof window?.ResizeObserver === 'function') {
    return true;
  }

  return false;
};

export const canShowHour = (hour, isAllAccess, lastAvailableHour) => {
  if (isAllAccess === false
    && typeof lastAvailableHour === 'number'
    && hour > lastAvailableHour) {
    return false;
  }

  return true;
};

export const convertPercent = (value) => (value !== undefined && value !== null
  ? Math.round(value * 100)
  : undefined
);

export const getBorderRadius = () => ({
  topLeft: 4,
  topRight: 4,
});

export const getDatasetOptions = (data, color) => {
  return {
    backgroundColor(context) {
      if (data[context.index]?.isHistory) {
        return 'rgb(0 0 0 / 0%)';
      }

      if (typeof color === 'function') {
        return color(context);
      }

      return color;
    },
    borderColor(context) {
      if (!data[context.index]?.isHistory) {
        return undefined;
      }

      if (typeof color === 'function') {
        return color(context);
      }

      return color;
    },
    borderRadius: getBorderRadius(),
    borderSkipped: false,
    borderWidth({ index }) {
      return data[index]?.isHistory ? 2 : 0;
    },
    minBarLength: 6,
  };
};

export const getLockedHoursPlugin = (isAllAccess, checkCanShowHour) => ({
  id: 'lockedHours',
  afterDatasetDraw(chart) {
    if (isAllAccess) {
      return;
    }

    const lockImage = new Image();

    // After the image has loaded _then_ it can be drawn in the chart.
    // SEE: https://stackoverflow.com/questions/15048279/drawimage-not-working

    lockImage.onload = () => {
      const {
        chartArea: { bottom },
        ctx,
        scales: { x },
      } = chart;

      if (!ctx) {
        // Exit if the onload callback is called and the canvas rendering context is null
        return;
      }

      const imageSize = isScreenLg() ? 16 : 10;
      const { data } = chart.data.datasets[0];

      for (let hour = 0; hour < data.length; hour += 1) {
        if (!checkCanShowHour(hour)) {
          ctx.drawImage(
            lockImage,
            x.getPixelForValue(hour) - (imageSize / 2),
            bottom - (imageSize * 1.5),
            imageSize,
            imageSize,
          );
        }
      }
    };

    lockImage.crossOrigin = 'Anonymous';
    lockImage.src = 'https://blizzard.opensnow.com/icons/custom/green-lock.svg';
  },
});

/**
 * Default Chart.js configuration for all 24 hour hourly bar charts
 * @todo Display ticks on x-axis with bars centered on ticks.
 */
export const getHourlyBarChartConfig = (theme = 'light', isAllAccess, lastAvailableHour) => ({
  data: {
    datasets: [],

    labels: ['12a', '', '', '3a', '', '', '6a', '', '', '9a', '', '', '12p', '', '', '3p', '', '', '6p', '', '', '9p', '', ''],
  },
  options: {
    hover: { mode: null },
    layout: {
      padding: {
        left: 2,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        align: 'end',
        anchor: 'end',
        color: theme === 'light' ? '#000' : '#dedede',
        font: {
          weight: 'bold',
        },
        formatter(value, context) {
          if (!canShowHour(context.dataIndex, isAllAccess, lastAvailableHour)) {
            return '';
          }

          if (!isScreenLg() && context.dataIndex % 2 === 0) {
            return '';
          }

          return value !== undefined ? Math.round(value) : '';
        },
        offset: 0,
        textAlign: 'center',
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        color: theme === 'light' ? undefined : '#fff',
        text: '',
      },
      tooltip: {
        enabled: false,
      },
    },
    responsive: canBeResponsive(),
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          color: chartColors.textDark(theme),
          font: {
            size: 11,
          },
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        beginAtZero: false,
        border: {
          dash({ index }) {
            return index === 0 ? false : [2, 2];
          },
          display: false,
          width: 0,
        },
        grid: {
          color: chartColors.borderColor(theme),
          display: true,
          drawTicks: false,
        },
        ticks: {
          count: 5,
          display: false,
        },
      },
    },
    tooltips: { enabled: false },
  },
  plugins: [
    getLockedHoursPlugin(
      isAllAccess,
      (hour) => {
        // If data for the hour can be displayed then return true
        if (canShowHour(hour, isAllAccess, lastAvailableHour)) {
          return true;
        }

        // On large screens locks can be shown for every hour, so return false.
        if (isScreenLg()) {
          return false;
        }

        // On small screens locks can be shown for every third hour, so that locks are displayed
        // above labeled hours; so return false.
        if (hour % 3 === 0) {
          return false;
        }

        // Otherwise return true so that nothing is rendered in the chart.
        return true;
      },
    ),
  ],
  type: 'bar',
});

export const getMaxValue = (values) => Math.max(...values.filter((value) => typeof value === 'number'));

export const getMinValue = (values) => Math.min(...values.filter((value) => typeof value === 'number'));

export const getTooltipOptions = () => ({
  backgroundColor: '#343a40', // var(--baltic-sea)
  cornerRadius: 6,
  enabled: true,
  padding: {
    // HACK: Use 2px more top padding to vertically center tooltip body. It's not clear what
    // option can be used to remove the spacing below the body.
    top: 8,
    right: 10,
    bottom: 6,
    left: 10,
  },
});

/**
 * Custom plug-in to draw a vertical line in the chart when a tooltip is displayed
 * @todo This plug-in isn't currently used, but may be used on the weather station
 * charts in the future.
 */
export const getVerticalLinePlugin = (params = {}) => {
  const {
    theme,
    padding = 0,
    getX = (chart) => {
      return chart.tooltip?.caretX;
    },
  } = params;

  return {
    id: 'vertical-line-plugin',

    beforeDatasetsDraw(chart) {
      if (chart.tooltip) {
        const x = getX(chart);

        if (typeof x === 'number') {
          const { ctx } = chart;
          const { top, bottom } = chart.chartArea;

          ctx.save();

          ctx.strokeStyle = chartColors.textRegular(theme);
          ctx.lineWidth = 1;
          ctx.setLineDash([2, 1]);

          ctx.beginPath();
          ctx.moveTo(x, top - padding);
          ctx.lineTo(x, bottom);
          ctx.stroke();
          ctx.restore();
        }
      }
    },
  };
};
